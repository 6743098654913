import BgeueLogo from "../images/bgue_logo.png";
import BgeLogo from "../images/bge_logo.png";
import { enGB } from "date-fns/locale"

export const statusNames = (status) => {
  const statuses = {
    ACTIVE: "Active",
    SUSPENDED: "Suspended",
    INACTIVE: "In-Active",
  };

  return statuses[status] || "-";
};

export const MONTH = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const statusColorTheme = {
  OPEN: { backgroundColor: "#ff0000", color: "#ffffff", borderColor: "#ff0000" },
  IN_PROGRESS: { backgroundColor: "#008000", color: "#ffffff", borderColor: "#008000" },
  READY_FOR_APPROVAL: { backgroundColor: "#ff1493", color: "#ffffff", borderColor: "#ff1493" },
  COMPLETED: { backgroundColor: "#0000ff", color: "#ffffff", borderColor: "#0000ff" },
};

export const COMPANY_NAME = {
  BGE: "BGE",
  BGUE: "BGUE",
};

export let CompanyLogo;

if (process.env.REACT_APP_COMPANY_NAME === COMPANY_NAME.BGE) {
  CompanyLogo = BgeLogo;
} else {
  CompanyLogo = BgeueLogo;
}
export const userType = (type) => {
  const category = {
    ADMIN: "Admin User",
    CLIENT: "Client User"
  }
  return category[type] || "-";
}

export const taskCategory = (categoryType) => {
  const categories = {
    corrective: "Corrective",
    remedial: "Remedial",
    preventative: "Preventative"
  }
  return categories[categoryType] || "-";
}
export const TASK_FIELDS = [
  {
    label: "Equipment Name",
    value: "assetId",
    field_key: "assetId"
  },
  {
    label: "Plant Name",
    value: "plantId",
    field_key: "plantId"
  },
  {
    label: "Supplier",
    value: "supplier",
    field_name: "Supplier",
  },
  {
    label: "Category",
    value: "category",
    field_key: "category",
  },
  {
    label: "Total Spares",
    value: "quantity",
    field_key: "quantity"
  },
  {
    label: "Contractual Quantity",
    value: "contractualQuantity",
    field_key: "contractualQuantity",
  },
  {
    label: "Is Key Component",
    value: "isKeyComponent",
    field_key: "isKeyComponent",
  },
  {
    label: "Description",
    value: "description",
    field_key: "description",
  },
  {
    label: "Ownership",
    value: "ownership",
    field_key: "ownership",
  },
  {
    label: "Installed Quantity",
    value: "installedQuantity",
    field_key: "installedQuantity",
  },
  {
    label: "Spare Quantity",
    value: "spareQuantity",
    field_key: "spareQuantity",
  },
  {
    label: "External Warehouse Quantity",
    value: "externalWarehouseQuantity",
    field_key: "externalWarehouseQuantity",
  },
  {
    label: "Bge Warehouse Quantity",
    value: "bgeWarehouseQuantity",
    field_key: "bgeWarehouseQuantity",
  },
  {
    label: "Supplier premises Quantity",
    value: "supplierPremisesQuantity",
    field_key: "supplierPremisesQuantity",
  },
  {
    label: "Part Number",
    value: "partNumber",
    field_key: "partNumber",
  },
];

export const STATUS_OPTION = {
  OPEN: "Open",
  IN_PROGRESS: "In Progress",
  READY_FOR_APPROVAL: "Ready for Approval",
  COMPLETED: "Completed",
};

export const EXCEL_DATE_FORMAT = `dd/MM/yyyy`;

export const LOCALE_DATE_ZONE = {
  locale: enGB,
};
