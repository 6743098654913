/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import ViewPvPlant from "../components/PvPlantView";
import { PvPlantManagementContext, withPvPlantManagementProvider } from "../PvPlantManagementProvider";
import { CorrectiveContext } from "../../maintenance/corrective/CorrectiveProvider";
import { RemedialContext } from "../../maintenance/remedial/RemedialProvider";
import { PreventativeTaskContext } from "../../maintenance/preventative/preventativeTasks/PreventativeTaskProvider";
import { ActivityLogContext } from "../../../providers/ActivtiyLogProvider";
import { StringContext } from "../../maintenance/string/StringProvider";
import { MaintenanceContext } from "../../../providers/Maintenance.provider";

const PvPlantManagementViewContainer = (props) => {
  const location = useLocation().search;
  const status = new URLSearchParams(location).get("status");
  const tab = new URLSearchParams(location).get("tab");
  const dashboard = new URLSearchParams(location).get("dashboard");


  const params = useParams();
  const { plantId } = params;
const correctiveContext = useContext(CorrectiveContext);
const remedialContext = useContext(RemedialContext);

  const { loadPvPlant, loadAllUsers, loadAllDnos, loadAllHealthAndSafeties, loadPvPlantTask, loadAttributeMasterList } =
    useContext(PvPlantManagementContext);
  const { loadActivityLogList, pagination: activityLogPagination } = useContext(ActivityLogContext);
  const [correctiveStatus, setCorrectiveStatus] = useState("");
  const [remedialStatus, setRemedialStatus] = useState("");
  const [preventativeStatus, setPreventativeStatus] = useState("");
  // const [stringStatus, setStringStatus] = useState("");

  useEffect(() => {
    if (status) {
      if (tab === "corrective") {
        setCorrectiveStatus(status);
      }
      if (tab === "remedial") {
        setRemedialStatus(status);
      }
      if (tab === "preventative") {
        setPreventativeStatus(status);
      }
      if (tab === "string") {
        setStringStatus(status);
      }
    }
  }, [status]);
  const {
    loadCorrectiveList,
    pagination: correctivePagination,
    filterParams: correctiveFilterParams,
    getTableSettings: correctiveGetTableSettings
  } = useContext(CorrectiveContext);

  const { pagination: remedialPagination, filterParams: remedialFilterParams, getTableSettings: remedialGetTableSettings, loadRemedialList } = useContext(RemedialContext);

  
  const { loadFilterOptions } = useContext(MaintenanceContext);

  const { loadOptions: loadPreventativeOptions, loadPreventativeTasks, getTableSettings } = useContext(PreventativeTaskContext);

  const filter = { entityId: plantId, entityType: "PLANT", ...activityLogPagination };

  const taskCategory = (categoryType) => {
    const categories = {
      corrective: "CORRECTIVE_PLANT",
      remedial: "REMEDIAL_PLANT",
      preventative: "PREVENTATIVE_PLANT"
    }
    return categories[categoryType];
  }

  const fetchTasksData = async () => {
    if (tab === "corrective") {
      const settings = await correctiveContext.getTableSettings({
        entityType: taskCategory(tab),
      });
      if (settings && plantId) {
        await loadCorrectiveList(settings, plantId, status);
      }
    }
    if (tab === "remedial") {
      const settings = await remedialContext.getTableSettings({
        entityType: taskCategory(tab),
      });
      if (settings && plantId) {
        await loadRemedialList(settings, plantId, status);
      }
    }
    if (tab === "preventative") {
      const settings = await getTableSettings({
        entityType: taskCategory(tab),
      });
      if (settings && plantId) {
        await loadPreventativeTasks(settings, plantId, status, dashboard);
      }
    }
  };

  useEffect(() => {
    if (tab !== "remedial" && tab !== "preventative" && tab !== "corrective") {
      loadActivityLogList(filter);
    }
  }, [activityLogPagination, tab])

  useEffect(() => {
    if (plantId) {
      loadPvPlant(plantId);
      loadAllUsers();
      loadAllDnos();
      loadAllHealthAndSafeties();
      loadFilterOptions();
      loadPreventativeOptions();
      loadPvPlantTask(plantId);
    }
  }, [plantId]);


  const { loadStringList, loadOptions } = useContext(StringContext);
  const {
    pagination: stringPagination,
    filterParams: stringFilter,
    setStringStatus,
    stringStatus,
  } = useContext(StringContext);

  useEffect(() => {
    if (plantId) {
      loadStringList(plantId, stringStatus);
    }
    if (plantId && !stringStatus) {
      loadStringList(plantId);
    }
  }, [stringPagination, stringFilter, stringStatus]);

  useEffect(() => {
    loadOptions();
  }, []);

  useEffect(() => {
    if (plantId && (tab !== 'basic') && taskCategory) {
      fetchTasksData()
    }
  }, [tab]);

  return (
    <React.Fragment>
      <Head title={`${plantId} - PV-Plant Management`} />
      <Content>
        <div style={{ overflow: "auto" }}>
          <ViewPvPlant />
        </div>
      </Content>
    </React.Fragment>
  );
};

export default withPvPlantManagementProvider(PvPlantManagementViewContainer);
